<template>
  <div class="assignment">
    <div class="column" style="min-height:calc(100vh - 175px); margin-bottom: 0;">
      <div class="flex-row justify-between align-items-center border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div v-if="targetInfo">
            <el-popover
              placement="bottom-start"
              width="500"
              trigger="hover">
              <div class="font-size-12 line-height-large">
                <div><span class="color-sub">数据名称：</span>{{ targetInfo.desc.name }}</div>
                <div><span class="color-sub">数据单位：</span>{{ targetInfo.desc.unit }}</div>
                <div><span class="color-sub">市场名称：</span>{{ targetInfo.desc.market }}</div>
                <div><span class="color-sub">规格牌号：</span>{{ targetInfo.desc.dataModel }}</div>
                <div><span class="color-sub">更新频率：</span>{{ targetInfo.desc.frequency }}</div>
                <div><span class="color-sub">统计日期：</span>{{ targetInfo.desc.time }}</div>
                <div><span class="color-sub">样本说明：</span>{{ targetInfo.desc.explain }}</div>
                <div><span class="color-sub">方法论：</span>{{ targetInfo.desc.func }}</div>
                <div><span class="color-sub">数据来源：</span>{{ targetInfo.desc.dataSource }}</div>
              </div>
              <span slot="reference"><span class="color-sub">预测目标：</span>{{targetInfo.targetName}}</span>
            </el-popover>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <span class="margin-right-10" v-if="lastSubmissionInfo">上次提交时间：{{lastSubmissionInfo.createTime}}</span>
          <el-button v-if="$store.state.loginResSCI.isAdmin" size="small" type="primary" :loading="publishLoading" @click="publish">提交</el-button>
        </div>
      </div>
      <div>
        <el-table
          ref="mainTable"
          :key="productId+'-'+modelFreq+'-'+$root.docWidth"
          :height="$root.docHeight-220"
          size="medium"
          v-loading="divisors.loading"
         :data="divisors.items">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column
            min-width="200"
            fixed="left"
            :show-overflow-tooltip="false"
            label="因子名称">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                  <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                    <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                    <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                    <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                    <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                    <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                    <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                    <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                  </div>
                </div>
                <span slot="reference" class="display-inline-block">
                  <span class="text-overflow-ellipsis-block" @click="getDivisorChart(props.row)">
                    <span class="custom-tag margin-right-10" color="red" size="mini" v-if="props.row.isOut">外生</span>
                    <span class="link-text">{{props.row.divisorName}}</span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <el-table-column v-for="(item,index) in divisors.dataList" :key="index" :label="item.dateCycleShow" min-width="110">
            <template slot-scope="props">
              <div class="flex-row align-items-center">
                <span v-if="index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide)" class="dot margin-right-5"></span>
                <el-tooltip :disabled="!(index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide))" content="较前值的涨跌幅超过10%" placement="top">
                  <span :class="{'color-sub':item[props.row.id].valueType==1}">{{ item[props.row.id].value | numFormat(2,'-')}}</span>
                </el-tooltip>  
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="赋值说明"
            :show-overflow-tooltip="true"
            prop="reason"
            min-width="200"
            fixed="right"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            prop="reason"
            width="50"
            fixed="right"
          >
            <template slot-scope="props">
             <span v-if="!props.row.isOut"  class="link-text text-nowrap" @click="getDivisorAdjustInfo(props.row)">调整</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 编辑因子弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName+'-赋值调整'" append-to-body :close-on-click-modal="false" width="50%" top="10vh" :visible.sync="divisorAdjustInfo.visible">
      <div style="height: calc(80vh - 180px); overflow: auto; padding: 0 10px;">
        <el-form :model="divisorAdjustInfo.form" ref="divisorForm" label-width="120px" :key="divisorAdjustInfo.visible" class="flex-row flex-wrap">
          <div v-for="(item,index) in divisorAdjustInfo.form.dataList" :key="index" style="width:50%;">
            <el-form-item v-if="item.valueType==1" :label="item.dateCycleShow" :prop="'dataList.'+index+'.hisValue'"
              :rules="[
                  { validator: validateNumber, trigger: ['blur','change'] }
                ]"
              >
                <el-input maxlength="10" v-model.trim="item.hisValue" autocomplete="off" placeholder="" :disabled="item.valueType==1"></el-input>
            </el-form-item>
            <el-popover
                v-else
                popper-class="opacity-80"
                placement="top-end"
                width="200"
                offset="30"
                trigger="hover"
            >
              <div>
                <template v-if="modelFreq=='week'">           
                  <div>AI预测：{{item.divisorRes4 | numFormat(2,'-')}}</div>
                </template>
                <template v-else>
                  <div>AI预测移动1期：{{item.divisorRes1| numFormat(2,'-')}}</div>
                  <div>AI预测移动3期：{{item.divisorRes3 | numFormat(2,'-')}}</div>
                  <div>AI预测移动12期：{{item.divisorRes12 | numFormat(2,'-')}}</div>
                </template>
              </div>
              <el-form-item slot="reference" :label="item.dateCycleShow" :prop="'dataList.'+index+'.sciValue'" 
              :rules="index<2||(modelFreq=='week'&&divisorAdjustInfo.form.dataList.slice(index+1,divisorAdjustInfo.form.dataList.length).some(v=>{return v.sciValue}))?[
                { required: true, message: '数值不能为空！', trigger: 'change' },
                { validator: validateNumber, trigger: ['blur','change'] }
              ]:[ { validator: validateNumber, trigger: ['blur','change'] }]">
                <el-input maxlength="10" v-model.trim="item.sciValue" placeholder="" autocomplete="off"></el-input>
              </el-form-item>
            </el-popover>
          </div>
          <el-form-item prop="reason" label="赋值说明" class="flex-auto" style="width:100%">
            <el-input maxlength="200"  show-word-limit v-model.trim="divisorAdjustInfo.form.reason" type="textarea" :rows="4" placeholder="请输入对价格影响的逻辑传导关系进行简要说明" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div>预测方法建议：{{ divisorAdjustInfo.form.suggestion }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex-row justify-center">
          <el-button @click="divisorAdjustInfo.visible=false">取消</el-button>
          <el-button type="primary" @click="submitDivisorAdjustForm('divisorForm')" :loading="divisorAdjustInfo.loading">保 存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 因子数据图详情弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
      <div class="flex-column" style="height: calc(70vh - 80px);" v-loading="divisorData.loading">
        <div><span>卓创赋值说明：</span>{{divisorData.info.sciReason}}</div>
        <div class="margin-top-5"><span>滨化赋值说明：</span>{{divisorData.info.befarReason}}</div>
        <Chart :option="divisorChart" class="flex-auto"></Chart>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Chart from "@/components/Chart";

export default {
  name: 'sciAssignment',
  components:{
    Chart
  },
  props:{
    curProduct:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data (){
    return{
      modelFreq:this.$route.params.modelFreq,
      productId:this.curProduct.productId,
      targetId:this.curProduct.targetInfo&&this.curProduct.targetInfo.id,

      targetInfo:null,//目标价信息

      divisors:{//因子赋值列表
        loading:false,
        items:[],
        dataList:[],
      },

      divisorActive:{},//当前编辑的因子
      divisorAdjustInfo:{//当前编辑的因子调整详情
        loading:false,
        visible:false,
        form:{}
      },

      divisorData:{//因子图表详情
        loading:false,
        visible:false,
        info:{}
      },

      lastSubmissionInfo:null,//最后提交返回信息
      publishLoading:false,//提交loading

      validateNumber:(rule, value, callback) => {
        //表单数字校验规则
        if (isNaN(value)) {
          callback(new Error('请输入数值类型！'));
        } else {
          callback();
        }
      },
    }
     
  },
  beforeCreate(){
    if(!['week','month'].some(val=>{return val==this.$route.params.modelFreq})){
      this.$router.replace({
          path: '/404',
      })
    }
  },
  created(){
    this.getData()
  },
  mounted(){
    
  },
  methods:{
    getData(){
      if(this.productId&&this.modelFreq){
        this.getTargetInfo()
        this.getDivisors()
        this.getLastSubmissionInfo()
      }
    },
    //获取目标价信息
    getTargetInfo(){
      this.$instance.get('/index/targetTopInfo',{
          params: {
           ...this.$store.state.basicParams,
           targetId:this.targetId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.targetInfo=res.data.info
        }else{
          this.targetInfo=null
        }
      })
      .catch(()=>{
        this.targetInfo=null
      })
    },
    //因子赋值=========================================================================
    //获取因子赋值列表
    getDivisors(){
      this.divisors.loading=true;
      this.$instance.get('/sciAsignment/getSciAssignmentValue',{
          params: {
           ...this.$store.state.basicParams,
           productId:this.productId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisors.items=res.data.info.items||[]
          this.divisors.dataList=res.data.info.dataList||[]
          this.$nextTick(()=>{
            this.$refs.mainTable.doLayout()
          })
        }else{
          this.divisors.items=[]
          this.divisors.dataList=[]
        }
      })
      .catch(()=>{
        this.divisors.loading=false;
        this.divisors.items=[]
        this.divisors.dataList=[]
      })
    },
     //获取因子赋值调整详情
     getDivisorAdjustInfo(divisor){
      this.divisorActive=divisor
      this.divisors.loading=true;
      this.$instance.get('/sciAsignment/getSciAdjustInfo',{
          params: {
            ...this.$store.state.basicParams,
            productId:this.productId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisorAdjustInfo.form=res.data.info;
          this.divisorAdjustInfo.visible=true;
        }
      })
      .catch(()=> {
        this.divisors.loading=false;
      }); 
    },
    saveDivisorAdjustInfo(){
      this.divisorAdjustInfo.loading=true
      let requestData={
        ...this.$store.state.basicParams,
        modelFreq:this.modelFreq,
        divisorId:this.divisorAdjustInfo.form.divisorId,
        reason:this.divisorAdjustInfo.form.reason,
        dataList:this.divisorAdjustInfo.form.dataList.map(v=>{return {
          "month": v.dateCycle,
          "valueType": v.valueType,
          "value": v.valueType==1?v.hisValue:v.sciValue
        }}),
      }
      this.$instance.post('/sciAsignment/saveSciExpertValue',requestData)
      .then(res=>{
        this.divisorAdjustInfo.loading=false
        if(res.data.code==0){
          this.divisorAdjustInfo.form.dataList.forEach((v,index)=>{
            this.divisors.dataList[this.divisors.dataList.length-this.divisorAdjustInfo.form.dataList.length+index][this.divisorActive.divisorId]={valueType:v.valueType,value:v.valueType==1?v.hisValue:v.sciValue}
          })
          this.divisorActive.reason=this.divisorAdjustInfo.form.reason
          this.divisorAdjustInfo.visible=false
        }
      })
      .catch(()=>{
        this.divisorAdjustInfo.loading=false
      })
    },
    submitDivisorAdjustForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
           this.saveDivisorAdjustInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisorActive=divisor
      this.divisorData={
        loading:true,
        visible:true,
        info:{}
      }
      this.$instance.get('/assignment/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            productId:this.productId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.info=res.data.info;
        }else{
          this.divisorData.info={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.info={}
      }); 
    },
    //获取最后提交信息
    getLastSubmissionInfo(){
      this.$instance.get('/assignment/getPublishDate',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq,
            expertType:'sci'
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.lastSubmissionInfo=res.data.info;
        }else{
          this.lastSubmissionInfo=null;
        }
      })
      .catch(()=> {
        this.lastSubmissionInfo=null;
      }); 
    },
    //提交
    publish(){
      if(this.lastSubmissionInfo&&this.lastSubmissionInfo.createTime){
        this.checkStatus()
      }else{
        this.publishConfirm()
      }
    },
    checkStatus(){
      this.publishLoading=true
      this.$instance.get('/assignment/checkAirFlowRunStatus',{
        params:{
          ...this.$store.state.basicParams,
          publishId:this.lastSubmissionInfo.id
        }
      })
      .then(res=>{
        this.publishLoading=false
        if(res.data.code==0){
          let msg=res.data.info.status==1?'模型正在运算中，提交会重新进行模型运算，确定要提交么？':'提交会覆盖上次提交内容，并重新进行模型运算，确定要提交么？'
          this.$confirm(msg, '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '继续提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.publishConfirm()
          })
          .catch(()=>{})
        }
      })
      .catch(()=>{
        this.publishLoading=false
      })
    },
    publishConfirm(){
      this.publishLoading=true
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        divisorIds:this.divisors.items.map(v=>{return v.divisorId}).join(',')
      }
      this.$instance.post('/sciAsignment/publish',requestData)
      .then(res=>{
        this.publishLoading=false
        if(res.data.code==0){
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.getLastSubmissionInfo()
        }
      })
      .catch(()=>{
        this.publishLoading=false
      })
    },
  },
  computed:{
     //赋值比较是否异常-提示
     numWarning(){
      return (a,b,c)=>{
        if(a&&this.$numFormat(b)){
          return Math.abs(b/a-1) > c?true:false
        }else{
         return false
        }
      }
    },
    //因子详情走势图
    divisorChart(){
      let chartData=this.divisorData.info
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisorActive.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dateCycleShow})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisorActive.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            lineStyle:{
              type:v.id=='hisValue'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['hisValue'])||!params.some(v=>v.seriesId=='hisValue')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    }
    
  },
  watch: {
    "$route.params.modelFreq" : {
     handler:function(val){
       this.modelFreq=val
       this.getData()
     },
     deep:true
    },
    "curProduct" : {
     handler:function(val){
      this.productId=val.productId
      this.targetId=val.targetInfo.id
      this.getData()
     },
     deep:true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
 .dot{
  width:5px;
  height: 5px;
  border-radius: 100%;
  background: #F49300;
}
</style>
<style  lang="scss">

</style>
